import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Row, Col, Button, Image } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import moment from "moment";

import he from "he"; // HTML senitizer

import { useGetSingleGuideQuery } from "../../services/guides";
import ComponentLoader from "../../components/Loader/ComponentLoader";

import { checkPermissions } from "../../utils/checkPermissions";

import IMAGES from "../../assets/images";

const GuideDetail = () => {
  let { id } = useParams();

  const user = useSelector((state) => state.profile.user);

  const formattedDate = (date) =>
    moment(date).format("DD MMMM YYYY [at] hh:mm A");

  const { data, isLoading } = useGetSingleGuideQuery(id, {
    skip: !id
  });

  if (isLoading) {
    return <ComponentLoader />;
  }

  if (!data?.guide) {
    return (
      <Row>
        <Col xs="auto pe-1">
          <Link to="/guides">
            <Button variant="outline-light btn-icon">
              <FeatherIcon icon="chevron-left" />
            </Button>
          </Link>
        </Col>
        <Col xs={12} className="text-center">
          <h3>Guide not found</h3>
        </Col>
      </Row>
    );
  }

  const canEditGuide = checkPermissions(user, "modify_guide");

  const decodeHtmlEntities = (html) => {
    return he.decode(html);
  };

  const html = decodeHtmlEntities(data?.guide?.content);

  return (
    <div>
      <div className="page-heading d-flex align-items-top gap-3">
        <Link to="/guides">
          <Button variant="outline-light btn-icon">
            <FeatherIcon icon="chevron-left" />
          </Button>
        </Link>
        <div>
          <h2 className="m-0">Guides</h2>
          <Row className="breadcrumb mb-0">
            <Col>
              <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                {id && (
                  <>
                    {/* Home link */}
                    <Link
                      to="/guides"
                      className="d-flex align-items-center gap-2 cursor-pointer text-body"
                    >
                      <Image
                        src={IMAGES.IconHome}
                        className="icon-xs"
                        alt="Guides"
                      />
                      <small>Home</small>
                    </Link>

                    {/* Current folder link */}
                    <FeatherIcon icon="chevron-right" className="icon-xs" />
                    <Link
                      to={`/guide-detail/${id ?? ""}`}
                      className="d-flex align-items-center gap-2 cursor-pointer text-body"
                    >
                      <Image
                        src={IMAGES.IconGuide}
                        className="icon-xs"
                        alt="Folder"
                      />
                      <small>{data?.guide?.name}</small>
                    </Link>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Row>
        <Col xs="12">
          <Row className="align-items-center mb-4 guides-title">
            <Col>
              <h3 className="mb-1">{data?.guide?.name}</h3>
              <p className="text-body small mb-0">
                {formattedDate(data?.guide?.updatedAt)}
              </p>
            </Col>
            {canEditGuide && (
              <Col xs="auto">
                <Link to={`/guide/${id}`}>
                  <Button variant="light">
                    <FeatherIcon icon="edit" />
                    Edit Document
                  </Button>
                </Link>
              </Col>
            )}
          </Row>

          {data?.guide?.imageLink && (
            <Image
              src={data?.guide?.imageLink}
              alt="Guide Image"
              className="mb-4 rounded-3"
              fluid
            />
          )}

          <div className="ck-content">
            <div
              dangerouslySetInnerHTML={{
                __html: html
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GuideDetail;
