import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Image, Spinner } from "react-bootstrap";
import DocumentUpload from "./UploadFile";
import { useCreateDocumentMutation } from "../../../services/documents";
import { useUploadFileMutation } from "../../../services/upload";
import { Notification } from "../../../components/ToastNotifcaiton";
import IMAGES from "../../../assets/images";

import { useDispatch, useSelector } from "react-redux";

import { baseApi } from "../../../services/baseApi";

const DocumentUploadModal = ({
  show,
  handleClose,
  id,
  setIsUploadingParent
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false); // State to manage overall loading
  const [uploadedCount, setUploadedCount] = useState(0); // State to manage count of uploaded files

  const dispatch = useDispatch();

  const [createDocument, { isLoading: isCreating }] =
    useCreateDocumentMutation();
  const [uploadFile] = useUploadFileMutation();

  useEffect(() => {
    if (uploadedCount === selectedFiles.length && isUploading) {
      setIsUploading(false);
      setIsUploadingParent(false);
      handleClose();
      Notification("Documents uploaded successfully", "success");

      // Need to Change this
      window.location.reload();
    }
  }, [
    uploadedCount,
    selectedFiles.length,
    isUploading,
    handleClose,
    setIsUploadingParent
  ]);

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      Notification("Please provide files to upload", "error");
      return;
    }

    setIsUploading(true);
    setIsUploadingParent(true);

    try {
      for (const file of selectedFiles) {
        // Upload the file to Azure
        const uploadResponse = await uploadFile(file).unwrap();
        const documentLink = uploadResponse.data.blobUrl;

        // Extract the file name without extension
        const originalFileName = file.name;
        const fileNameWithoutExtension = originalFileName.replace(
          /\.[^/.]+$/,
          ""
        );

        // Create the document with the uploaded file link and original file name without extension
        const documentData = {
          name: fileNameWithoutExtension,
          documentLink,
          ...(id && { folderId: id })
        };

        await createDocument(documentData).unwrap();

        // Update uploaded count
        setUploadedCount((prevCount) => prevCount + 1);
      }
    } catch (error) {
      Notification("Failed to upload documents", "error");
      console.error("Failed to upload documents:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => !isUploading && handleClose()} // Prevent closing the modal during upload
      backdrop="static"
      keyboard={false}
      animation={true}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Image src={IMAGES.IconUpload} alt="Upload Document" />
          Upload Documents
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <DocumentUpload onFileSelect={setSelectedFiles} imgLink={null} />
          </Form.Group>
          {isUploading && (
            <div className="text-center mt-3">
              <Spinner animation="border" role="status"></Spinner>
              <div>
                Uploading {uploadedCount} of {selectedFiles.length} files...
              </div>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={() => !isUploading && handleClose()}
          disabled={isUploading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleUpload}
          disabled={isUploading || isCreating}
        >
          {isUploading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Saving...
            </>
          ) : (
            "Save"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentUploadModal;
