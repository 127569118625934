import { useMemo, useState, useEffect } from "react";
import { useGetFoldersDocumentsQuery } from "../services/documents";
import Fuse from "fuse.js";

// Recursive function to search within a folder and its subfolders
function searchInFolder(folder, searchTerm) {
  // Combine documents and subfolders for searching
  const itemsToSearch = [
    ...folder.documents.map((doc) => ({ type: "document", ...doc }))
  ];

  // Set up Fuse.js options
  const fuseOptions = {
    keys: ["name"], // Search by name
    includeScore: true, // Include a score in the search results
    threshold: 0.4 // Adjust this threshold to control the fuzzy matching (0.0 means exact match)
  };

  const fuse = new Fuse(itemsToSearch, fuseOptions);
  const results = fuse.search(searchTerm).map((result) => result.item);

  // Recursively search in subfolders
  folder.subfolders.forEach((subfolder) => {
    const subResults = searchInFolder(subfolder, searchTerm);
    results.push(...subResults);
  });

  return results;
}

const useFuseSearch = (query) => {
  const { data: foldersfilesData, isLoading: foldersfilesIsLoading } =
    useGetFoldersDocumentsQuery({});

  const [results, setResults] = useState([]);

  useEffect(() => {
    if (query && foldersfilesData) {
      // Initialize an empty array to collect all search results
      let allResults = [];

      // Iterate over all top-level folders in foldersfilesData
      foldersfilesData.data.forEach((folder) => {
        const folderResults = searchInFolder(folder, query);
        allResults = [...allResults, ...folderResults];
      });

      setResults(allResults);
    } else {
      setResults([]);
    }
  }, [query, foldersfilesData]);

  return {
    results,
    isLoading: foldersfilesIsLoading
  };
};

export default useFuseSearch;
