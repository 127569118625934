import React, { useState, useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Accordion, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import IMAGES from "../assets/images";

import { checkPermissionsAll } from "../utils/checkPermissions";

const Aside = forwardRef((props, ref) => {
  const { isVisible, setIsVisible } = props;
  const [activeKey, setActiveKey] = useState(null);
  const location = useLocation();

  const user = useSelector((state) => state.profile.user);

  const handleItemClick = (eventKey) => {
    setActiveKey(eventKey);

    if (window.innerWidth <= 575) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    // Function to determine the active key based on the current path
    const setActiveTabBasedOnUrl = (path) => {
      if (path === "/") {
        setActiveKey("home");
      } else if (path.includes("/documents-center")) {
        setActiveKey("documentscenter");
      } else if (path.includes("/guide")) {
        setActiveKey("guides");
      } else if (path.includes("/user-management")) {
        setActiveKey("usermanagement");
      } else if (path.includes("/file")) {
        setActiveKey("documentscenter");
      } else if (path.includes("/update")) {
        setActiveKey("updates");
      }
    };

    setActiveTabBasedOnUrl(location.pathname + location.hash);
  }, [location]);

  const userAccess = checkPermissionsAll(user, [
    "view_user",
    "add_user",
    "modify_user",
    "delete_user",
    "view_roles",
    "add_roles",
    "modify_roles",
    "delete_roles",
    "get_users_roles"
  ]);

  const guideAccess = checkPermissionsAll(user, [
    "view_guide",
    "add_guide",
    "modify_guide",
    "delete_guide"
  ]);

  const documentAccess = checkPermissionsAll(user, [
    "view_document",
    "add_document",
    "modify_document",
    "delete_document"
  ]);

  return (
    <aside ref={ref} className={`aside ${isVisible ? "open" : "closed"}`}>
      <div className="aside-holder">
        <Link to="/" className="logo d-flex justify-content-center w-100 mt-4">
          <Image src={IMAGES.Logo} alt="Logo" />
        </Link>
        <div className="content">
          <Accordion activeKey={activeKey}>
            {
              <Accordion.Item eventKey="home" className="nav-item">
                <Accordion.Header onClick={() => handleItemClick("guides")}>
                  <Link to={"/"}>
                    <Image src={IMAGES.IconHome} alt="Home" />
                    Welcome
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
            }

            {guideAccess && (
              <Accordion.Item eventKey="guides" className="nav-item">
                <Accordion.Header onClick={() => handleItemClick("guides")}>
                  <Link to={"/guides"}>
                    <Image src={IMAGES.IconGuide} alt="Guides" />
                    Guides
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {documentAccess && (
              <Accordion.Item eventKey="documentscenter" className="nav-item">
                <Accordion.Header
                  onClick={() => handleItemClick("documentscenter")}
                >
                  <Link to={"/documents-center"}>
                    <Image src={IMAGES.IconDocCenter} alt="Documents Center" />
                    Documents Center
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
            )}

            {documentAccess && (
              <Accordion.Item eventKey="updates" className="nav-item">
                <Accordion.Header onClick={() => handleItemClick("updates")}>
                  <Link to={"/updates"}>
                    <Image src={IMAGES.IconUpdate} alt="Updates" />
                    Products & Alerts
                  </Link>
                </Accordion.Header>
              </Accordion.Item>
            )}
          </Accordion>

          <Accordion activeKey={activeKey} className="pt-4">
            <h5 className="mb-3">Quick Links</h5>
            <Accordion.Item eventKey="fiserv" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://resources.fiservclients.com/green/FD_Common/Login.aspx?ReturnUrl=%2fgreen%2f"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={IMAGES.IconFiserve} alt="Fiserv" />
                  Fiserv Clients Resources
                </a>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="fiserv" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://www.youraccessone.com/64_ms_login.aspx?ReturnUrl=%2f64_rpt_Home.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={IMAGES.IconAccessOne} alt="Fiserv" />
                  Accessone
                </a>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="fiserv" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://www.businesstrack.com/manage/landing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={IMAGES.IconBusinessTrack} alt="Fiserv" />
                  Business Track
                </a>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="fiserv" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://cygnus.isoquote.com/session/new"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={IMAGES.IconisoAmp} alt="Fiserv" />
                  ISO Amp
                </a>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="cygnus" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://cygnuspay.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={IMAGES.IconTalentLMS}
                    alt="Cygnus Payment Solutions"
                  />
                  Cygnus Payment Solutions
                </a>
              </Accordion.Header>
            </Accordion.Item>

            <Accordion.Item eventKey="rippling" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://www.rippling.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={IMAGES.IconRippling} alt="User Management" />
                  Rippling
                </a>
              </Accordion.Header>
            </Accordion.Item>

            <Accordion.Item eventKey="TalentLMS" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://cygnuspay.talentlms.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={IMAGES.IconTalentLMS} alt="TalentLMS" />
                  TalentLMS
                </a>
              </Accordion.Header>
            </Accordion.Item>

            <Accordion.Item eventKey="Iris" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://cygnus.iriscrm.com/v2/login?ref=/v2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={IMAGES.IconIRIS} alt="Iris" />
                  Iris CRM
                </a>
              </Accordion.Header>
            </Accordion.Item>

            <Accordion.Item eventKey="Zoho" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://forms.zohopublic.com/cygnuspayments/form/TerminalRequestForm1/formperma/NEg30x4Nk_Xj7T7M5tZVUXUybWmUFUz1tQfZgU_lKgU"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={IMAGES.IconZohoForms} alt="Zoho" />
                  Terminal Request Form
                </a>
              </Accordion.Header>
            </Accordion.Item>

            <Accordion.Item eventKey="Outlook" className="nav-item">
              <Accordion.Header>
                <a
                  href="https://outlook.office.com/mail/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={IMAGES.IconOutlook} alt="Outlook" />
                  Outlook
                </a>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="aside-footer">
        <div className="d-flex align-content-center justify-content-center gap-3 pb-4">
          <Link to={"https://www.instagram.com/cygnuspayments"} target="_blank">
            <Image src={IMAGES.IconInstagram} alt="Logo" className="icon-sm" />
          </Link>
          <Link
            to={"https://www.linkedin.com/company/cygnuspayments"}
            target="_blank"
          >
            <Image src={IMAGES.IconLinkedIn} alt="Logo" className="icon-sm" />
          </Link>
          <Link to={"https://www.facebook.com/cygnuspayments"} target="_blank">
            <Image src={IMAGES.IconFacebook} alt="Logo" className="icon-sm" />
          </Link>
        </div>
      </div>
    </aside>
  );
});

export default Aside;
