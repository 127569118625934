import CreateUser from "../pages/AdminSettings/CreateUser";
import UserManagement from "../pages/UserManagement";
import CreateUserClass from "../pages/AdminSettings/CreateUserClass";
import UploadDocuments from "../pages/AdminSettings/UploadDocuments";
import Guides from "../pages/Guides";
import DocumentsCenter from "../pages/DocumentsCenter";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import GuideDetail from "../pages/Guides/detail";
import AddNewGuide from "../pages/Guides/add";
import ProfileSettings from "../pages/ProfileSettings";
import InventoryManagement from "../pages/InventoryManagement";
import InventoryManagementDetail from "../pages/InventoryManagement/detail";
import Home from "../pages/Home";

import Updates from "../pages/Updates";
import AddNewUpdate from "../pages/Updates/add";
import UpdateDetails from "../pages/Updates/detail";

import File from "../pages/DocumentsCenter/file";
import PollSettings from "../pages/Settings";

// Dashboard CMS
import ExecutivesMsg from "../pages/Home/CMS/executivesMsg";
import News from "../pages/Home/CMS/news";
import Shotlight from "../pages/Home/CMS/spotlights";
import Brochures from "../pages/Home/CMS/brochures";
import Gallery from "../pages/Home/CMS/gallery";
import Alert from "../pages/Home/CMS/alert";
import Polls from "../pages/Home/CMS/polls";

const Pages = [
  {
    id: "0",
    path: "/",
    element: <Home />
  },
  {
    id: "1",
    path: "create-user",
    element: <CreateUser />
  },
  {
    id: "2",
    path: "user-management",
    element: <UserManagement />
  },
  {
    id: "3",
    path: "create-user-class",
    element: <CreateUserClass />
  },
  {
    id: "4",
    path: "upload-documents",
    element: <UploadDocuments />
  },
  {
    id: "5",
    path: "guides",
    element: <Guides />
  },
  {
    id: "6",
    path: "documents-center/:id?",
    element: <DocumentsCenter />
  },
  {
    id: "7",
    path: "errorpage",
    element: <ErrorPage />
  },
  {
    id: "8",
    path: "guide-detail/:id",
    element: <GuideDetail />
  },
  {
    id: "9",
    path: "guide/:id?",
    element: <AddNewGuide />
  },
  {
    id: "9",
    path: "profile-settings",
    element: <ProfileSettings />
  },
  {
    id: "10",
    path: "inventory-management",
    element: <InventoryManagement />
  },
  {
    id: "11",
    path: "inventory-management-detail",
    element: <InventoryManagementDetail />
  },
  {
    id: "11",
    path: "file/:id?",
    element: <File />
  },
  {
    id: "12",
    path: "poll-settings",
    element: <PollSettings />
  },
  {
    id: "13",
    path: "dashboard/settings/executives",
    element: <ExecutivesMsg />
  },
  {
    id: "14",
    path: "dashboard/settings/news",
    element: <News />
  },
  {
    id: "15",
    path: "dashboard/settings/spotlight",
    element: <Shotlight />
  },
  {
    id: "16",
    path: "dashboard/settings/brochures",
    element: <Brochures />
  },
  {
    id: "17",
    path: "dashboard/settings/gallery",
    element: <Gallery />
  },
  {
    id: "18",
    path: "dashboard/settings/alerts",
    element: <Alert />
  },
  {
    id: "19",
    path: "dashboard/settings/polls",
    element: <Polls />
  },
  {
    id: "20",
    path: "updates",
    element: <Updates />
  },
  {
    id: "21",
    path: "update/:id?",
    element: <AddNewUpdate />
  },
  {
    id: "8",
    path: "update-detail/:id",
    element: <UpdateDetails />
  }
];

export default Pages;
