const IMAGES = {
  Logo: require("./logo.png"),
  LogoLight: require("./logo-light.png"),
  LogoWhite: require("./logo-white.png"),
  Logo1: require("./logo1.png"),
  LogoLight1: require("./logo-light1.png"),
  LogoWhite1: require("./logo-white1.png"),

  banner: require("./banner.png"),
  login: require("./login.png"),

  IconDefault: require("./icons/default.png"),
  IconAdminSettings: require("./icons/admin-settings.png"),
  IconDocCenter: require("./icons/doc-center.png"),
  IconFeedback: require("./icons/feedback.png"),
  IconGuide: require("./icons/guide.png"),
  IconIdea: require("./icons/idea.png"),
  IconRocket: require("./icons/rocket.png"),
  IconSettings: require("./icons/settings.png"),
  IconUpload: require("./icons/upload.png"),
  IconHome: require("./icons/home.png"),
  IconProfile: require("./icons/profile.png"),
  IconInventoryMGT: require("./icons/inventory-mgt.png"),
  IconCondition: require("./icons/condition.png"),
  IconFile: require("./icons/file.png"),
  IconFolder: require("./icons/folder.png"),
  IconPlusCircle: require("./icons/plus-circle.png"),
  IconCheck: require("./icons/check.png"),
  IconCheckCircle: require("./icons/check-circle.png"),
  IconCross: require("./icons/cross.png"),
  IconCrossCircle: require("./icons/cross-circle.png"),
  IconLock: require("./icons/lock.png"),
  IconEvents: require("./icons/news and events.png"),
  IconCalendar: require("./icons/calendar.png"),
  IconCalendarHoliday: require("./icons/calendar-holiday.png"),
  IconCalendarMarketing: require("./icons/calendar-marketing.png"),
  IconFire: require("./icons/fire.png"),
  IconBell: require("./icons/bell.png"),
  IconNewsBullet: require("./icons/news-bullet.png"),
  IconUpdate: require("./icons/update.png"),
  IconBrochures: require("./icons/brochures.png"),
  IconStock: require("./icons/stock.png"),
  IconSpotlights: require("./icons/spotlights.png"),
  IconGallery: require("./icons/gallery.png"),
  IconMessage: require("./icons/message.png"),
  IconSearch: require("./icons/search.png"),
  IconPoll: require("./icons/poll.png"),
  IconThumb: require("./icons/thumb.png"),
  Icon404: require("./icons/404.png"),

  IconPdf: require("./extensions/pdf.png"),
  IconDoc: require("./extensions/doc.png"),
  IconXls: require("./extensions/xls.png"),
  IconZip: require("./extensions/zip.png"),
  IconImg: require("./extensions/img.png"),
  IconExe: require("./extensions/exe.png"),
  IconPPT: require("./extensions/ppt.png"),

  IconFiserve: require("./icons/fiserve.png"),
  IconRippling: require("./icons/rippling.png"),
  IconTalentLMS: require("./icons/talentlms.png"),
  IconIRIS: require("./icons/iris.png"),
  IconZohoForms: require("./icons/zohoforms.png"),
  IconAccessOne: require("./icons/accessone.png"),
  IconisoAmp: require("./icons/isoamp.png"),
  IconBusinessTrack: require("./icons/businesstrack.png"),

  IconInstagram: require("./icons/instagram.png"),
  IconFacebook: require("./icons/facebook.png"),
  IconWhatsapp: require("./icons/whatsapp.png"),
  IconLinkedIn: require("./icons/linkedin.png"),
  IconOutlook: require("./icons/outlook.png"),
  IconSharePoint: require("./icons/sharepoint.png"),
  IconSocial: require("./icons/social1.png"),

  IconWeather: require("./icons/weather.png"),
  WeatherClearDay: require("./weather/clear day.png"),
  WeatherSunny1: require("./weather/sunny1.png"),
  WeatherSunny2: require("./weather/sunny2.png"),
  WeatherCloudy: require("./weather/cloudy.png"),
  WeatherRain: require("./weather/rain.png"),
  WeatherCloudyAndRainy: require("./weather/cloudy and rainy.png"),
  WeatherHeavyRainAndStrom: require("./weather/heavyrain and storm.png"),
  WeatherFullMoon: require("./weather/full moon.png"),
  WeatherClearNight: require("./weather/clear night.png"),
  WeatherNight: require("./weather/night.png"),
  WeatherThunder: require("./weather/thunder.png"),
  WeatherNightCloudy: require("./weather/night cloudy.png"),
  WeatherNightRain: require("./weather/night rain.png"),

  NewsDefaultImage: require("./icons/news-default.png"),
  NewsLatterMain: require("./newsletter-main.png"),
  NewsLatter1: require("./newsletter-1.png"),
  NewsLatter2: require("./newsletter-2.png"),
  NewsLatter3: require("./newsletter-3.png"),
  NewsLatter4: require("./newsletter-4.png"),
  NewsLatter5: require("./newsletter-5.png"),
  NewsLatter6: require("./newsletter-6.png"),
  NewsLatter7: require("./newsletter-7.png"),
  NewsLatter8: require("./newsletter-8.png"),
  NewsLatter9: require("./newsletter-9.png"),
  NewsLatter10: require("./newsletter-10.png"),
  NewsLatter11: require("./newsletter-11.png"),
  NewsLatter12: require("./newsletter-12.png"),
  NewsLatter13: require("./newsletter-13.png"),
  NewsLatter14: require("./newsletter-14.png"),

  DefaultImage: require("./icons/default.png")
};
export default IMAGES;
