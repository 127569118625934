import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Modal, Image, Spinner } from "react-bootstrap";
import {
  useCreateFolderMutation,
  useUpdateFolderMutation
} from "../../../services/documents";
import { Notification } from "../../../components/ToastNotifcaiton";
import IMAGES from "../../../assets/images";

const CreateFolderModal = ({ show, handleClose, parentId, folderData }) => {
  const [folderName, setFolderName] = useState("");
  const [createFolder, { isLoading: isCreating }] = useCreateFolderMutation();
  const [updateFolder, { isLoading: isUpdating }] = useUpdateFolderMutation();

  const inputRef = useRef(null);

  useEffect(() => {
    if (folderData) {
      setFolderName(folderData.name);
    } else {
      setFolderName("");
    }
  }, [folderData]);

  useEffect(() => {
    if (show) {
      inputRef.current && inputRef.current.focus();
    }
  }, [show]);

  const handleCreateOrUpdate = async () => {
    if (!folderName.trim()) {
      Notification("Folder name is required", "warning");
      return;
    }

    const folderPayload = {
      name: folderName,
      ...(parentId && { parentFolder: parentId })
    };


    try {
      if (folderData) {
        await updateFolder({
          id: folderData._id,
          body: { name: folderPayload.name }
        }).unwrap();
        Notification("Folder updated successfully", "success");
      } else {
        await createFolder(folderPayload).unwrap();
        Notification("Folder created successfully", "success");
      }
      handleClose();
      setFolderName("");
    } catch (error) {
      Notification(
        error?.data?.error || "Failed to create/update folder",
        "error"
      );
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission if form is used
      handleCreateOrUpdate();
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Image src={IMAGES.IconFolder} alt="Create Folder" />
            {folderData ? "Update Folder" : "Create Folder"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                {folderData ? "Update Folder" : "New Folder"}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter folder name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                onKeyDown={handleKeyPress}
                ref={inputRef}
              />
              <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isCreating || isUpdating}
            variant="light"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            disabled={isCreating || isUpdating}
            variant="primary"
            onClick={handleCreateOrUpdate}
          >
            {isCreating || isUpdating ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : folderData ? (
              "Update"
            ) : (
              "Create"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateFolderModal;
